import { transformArrayForOptions } from "utils/array";

export enum IFormatFile {
    SRT = "srt",
    VTT = "vtt",
}

export enum IOutputStartIndex {
    STARTING_0 = 0,
    STARTING_1 = 1,
}

const optionsFormatArray = [IFormatFile.SRT, IFormatFile.VTT];
const optionsIndexArray = ["0", "1"];

const optionsFormatList = transformArrayForOptions(optionsFormatArray);
const optionsIndexList = transformArrayForOptions(optionsIndexArray);

export { optionsFormatList, optionsIndexList };
