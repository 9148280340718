import React from "react";
import "./style.scss";
export type Type = "info" | "error";

interface IError {
    text?: string;
    type?: Type;
}

const Error: React.FC<IError> = ({ text, type, ...props }) => {
    return <span className={`error-text ${type}`}>{text}</span>;
};

Error.defaultProps = {
    text: "Field is required",
    type: "error",
};

export default Error;
