import axios, { AxiosInstance } from "axios";
import {
    DOWNLOAD_SOURCE,
    DOWNLOAD_SUBTITLES,
    LANGUAGES,
    LOGIN,
    LOGOUT,
    ME,
    NOTIFICATIONS,
    PROJECTS,
    SAVE_UPLOAD,
    SELECT_PROFILE,
    TRANSCRIPTIONS,
} from "config/apiEndpoints";
import { saveAs } from "file-saver";

class ScribaApi {
    client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            },
        });
    }

    async call({ thunkApi, method, url, data, headers, params }: any) {

        let options: any = {
            method,
            url,
            data,
            params,
            headers: {
                ...this.client.defaults.headers.common,
                ...headers,
            },
        };

        // Handling download of Media (mp3, jpeg etc etc)
        if (url.includes("download")) {
            options = {
                ...options,
                responseType: "blob",
            };
        }
        try {
            const res = await this.client(options);
            return res.data;
            // return getResponseData ? res.data : res;
        } catch (error: any) {
            return thunkApi.rejectWithValue(
                error.response
                    ? {
                        code: error.response.status,
                        message: error.response.data.message,
                    }
                    : error.toString()
            );
        }
    }

    async login({ thunkApi, data }: any) {
        return await this.call({ thunkApi, method: "post", url: LOGIN, data });
    }

    async logout({ thunkApi }: any) {
        return await this.call({ thunkApi, method: "post", url: LOGOUT });
    }

    async me({ thunkApi }: any) {
        return await this.call({ thunkApi, method: "get", url: ME });
    }

    async changeGlobalProfile({ thunkApi, data }: any) {
        return await this.call({
            thunkApi,
            method: "post",
            url: SELECT_PROFILE,
            data,
        });
    }

    async getProject({ thunkApi, projectId }: any) {
        return await this.call({
            thunkApi,
            method: "get",
            url: `${PROJECTS}/${projectId}`,
        });
    }

    async createProject({ thunkApi, data }: any) {
        return await this.call({
            thunkApi,
            method: "post",
            url: `${PROJECTS}`,
            data,
        });
    }

    async updateProject({ thunkApi, projectId, data }: any) {
        return await this.call({
            thunkApi,
            method: "put",
            url: `${PROJECTS}/${projectId}`,
            data,
        });
    }

    async getProjects({ thunkApi, params }: any) {
        return await this.call({ thunkApi, method: "get", url: `${PROJECTS}`, params });
    }

    async assochUploadedMediaToProject({ thunkApi, data }: any) {
        return await this.call({
            thunkApi,
            method: "post",
            url: `${SAVE_UPLOAD}`,
            data,
        });
    }

    async getLanguages({ thunkApi }: any) {
        return await this.call({
            thunkApi,
            method: "get",
            url: `${LANGUAGES}`,
        });
    }

    // Transcriptions Api
    async createNewProjTranscription({ thunkApi, projectId, data }: any) {
        return await this.call({
            thunkApi,
            method: "post",
            url: `${PROJECTS}/${projectId}/${TRANSCRIPTIONS}`,
            data,
        });
    }

    async getProjTranscriptionList({ thunkApi, projectId }: any) {
        return await this.call({
            thunkApi,
            method: "get",
            url: `${PROJECTS}/${projectId}/${TRANSCRIPTIONS}`,
        });
    }

    async getSingleProjTranscriptionList({ thunkApi, projectId }: any) {
        return await this.call({
            thunkApi,
            method: "get",
            url: `${PROJECTS}/${projectId}/${TRANSCRIPTIONS}`,
        });
    }

    async downloadProjectTranscriptionList({
        thunkApi,
        projectId,
        transcriptionId,
        save_as,
        mime,
    }: any) {
        const response = await this.call({
            thunkApi,
            method: "get",
            url: `${PROJECTS}/${projectId}/${TRANSCRIPTIONS}/${transcriptionId}/${DOWNLOAD_SUBTITLES}`,
        });

        const blob = new Blob([response], { type: mime });
        return saveAs(blob, save_as);
    }

    // Notifications
    async getUnreadNotifications({ thunkApi }: any) {
        return await this.call({
            thunkApi,
            method: "get",
            url: `${NOTIFICATIONS}`,
        });
    }

    async markNotificationAsRead({ thunkApi, id }: any) {
        return await this.call({
            thunkApi,
            method: "post",
            url: `${NOTIFICATIONS}/${id}`,
        });
    }

    // Download Source file
    async downloadSourceFile({ thunkApi, id, mime, save_as }: any) {
        const response = await this.call({
            thunkApi,
            method: "get",
            url: `${PROJECTS}/${id}/${DOWNLOAD_SOURCE}`,
        });

        const blob = new Blob([response], { type: mime });
        saveAs(blob, save_as);
    }
}

export default ScribaApi;
