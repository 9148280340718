import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/user-slice";
import projectsSlice from "./slices/projects-slice";
import languagesSlice from "./slices/languages-slice";
import modalsSlice from "./slices/modals-slice";
import transcriptionsSlice from "./slices/transcriptions-slice";
import notificationsSlice from "./slices/notifications-slice";

export const store = configureStore({
    reducer: {
        user: userSlice,
        projects: projectsSlice,
        languages: languagesSlice,
        modals: modalsSlice,
        transcriptions: transcriptionsSlice,
        notifications: notificationsSlice,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
