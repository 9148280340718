/* eslint-disable react-hooks/rules-of-hooks */
import { selectUser } from "../store/slices/user-slice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "ui/Spinner/Spinner";
import { useEffect } from "react";
import { AppDispatch } from "store/store";
import { removeSelectedTranscription } from "store/slices/transcriptions-slice";
import {
    getProject,
    removeSelectedProjects,
    selectProjects,
    setSelectedProject,
} from "store/slices/projects-slice";

/**
 * HOC that handles the profile change, when user select the dropdown menu or click on a notification received regarding another project
 * @param Component
 * @returns
 */
const WithProfiles = (Component: any) => (props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const { loadingChoosenProfile } = useSelector(selectUser);
    const { selectedProject, navigateToProject } = useSelector(selectProjects);

    useEffect(() => {
        // Clean up store
        dispatch(removeSelectedTranscription());
        dispatch(removeSelectedProjects());

        // If navigate from notification click
        if (navigateToProject && selectedProject) {
            dispatch(setSelectedProject(selectedProject));
            dispatch(getProject(selectedProject));
        } else {
            // If normal navigation select switching
            dispatch(setSelectedProject(null));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingChoosenProfile, navigateToProject, dispatch]);

    return loadingChoosenProfile ? (
        <Spinner isFullPage={true} />
    ) : (
        <Component {...props} />
    );
};

export default WithProfiles;
