import axios from "axios";
import { ScribaInstance } from "scriba/Scriba";

// const uploadFiles = async () => {
//     if (props.onUploadingStart) {
//       props.onUploadingStart()
//     }
//     setUploading(true)
//     setErrors(null)
//     let uploadProgress = []
//     files.forEach((file, index) => {
//       // data.append('file_' + index, file);
//       console.log("uploadFiles2", file)

//       Vapor.store(file, {
//         baseURL: client.defaults.baseURL,
//         headers: {
//           "Content-type": file.type
//         },
//         visibility: 'public-read',
//         contentType: file.type,
//         progress: progress => {
//           uploadProgress[index] = Math.round(progress * 100);
//           setProgress(uploadProgress)
//         }
//       }).then(async (response) => {
//         console.log("uploadFiles", {
//           uuid: response.uuid,
//           key: response.key,
//           bucket: response.bucket,
//           name: file.name,
//           mime: file.type,
//           size: file.size
//         })

//         let saveRes = null
//         let postParams = {}

//         if (props.import) {
//           postParams = {
//             uuid: response.uuid,
//             key: response.key,
//             bucket: response.bucket,
//             name: file.name,
//             mime: file.type,
//             size: file.size,
//             event_id: props.eventId,
//             episode_id: props.episodeId
//           }
//           saveRes = await importSubscriptions(props.eventId, postParams)
//         }
//         else {
//           postParams = {
//             uuid: response.uuid,
//             key: response.key,
//             bucket: response.bucket,
//             name: file.name,
//             mime: file.type,
//             size: file.size,
//             event_id: props.eventId
//           }
//           saveRes = await saveMedia(postParams)
//         }

//         if (props.afterUploadSuccess) {
//           props.afterUploadSuccess(saveRes, postParams)
//         }
//         // axios.post('/api/profile-photo', {
//         //     uuid: response.uuid,
//         //     key: response.key,
//         //     bucket: response.bucket,
//         //     name: this.$refs.file.files[0].name,
//         //     content_type: this.$refs.file.files[0].type,
//         // })
//       }).catch(e => {
//         console.log("uploadFiles", e)
//         setErrors(e)
//         // console.log("uploadFiles", e.response.data)
//       }).finally(() => {
//         setUploading(false)
//       })
//     });

// export const saveMedia = async (params) => {
//     const url = [
//         MEDIA,
//         SAVE_UPLOADED
//     ].join("/")
//     const res = await client.post(url, params)
//     return _.get(res, "data.data")
// }

class Vapor {
    signedStorageUrl?: string;

    setSignedStorageUrl(url: string): Vapor {
        this.signedStorageUrl = url;
        return this;
    }

    /**
     * Store a file in S3 and return its UUID, key, and other information.
     */
    async store(file: { type: any; name: string }, options: any = {}) {
        if (ScribaInstance.getToken()) {
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + ScribaInstance.getToken();
        }
        const response = await axios.post(
            "/media/signed-storage-url",
            {
                bucket: options.bucket || "",
                content_type: options.contentType || file.type,
                expires: options.expires || "",
                visibility: options.visibility || "",
            },
            {
                baseURL: options.baseURL || null,
                headers: options.headers || {},
                ...options.options,
            }
        );

        let headers = response.data.headers;

        if ("Host" in headers) {
            delete headers.Host;
        }

        if ("Authorization" in headers) {
            delete headers.Authorization;
        }

        if (typeof options.progress === "undefined") {
            options.progress = () => {};
        }

        const cancelToken = options.cancelToken || "";
        delete axios.defaults.headers.common["Authorization"];
        await axios.put(response.data.url, file, {
            cancelToken: cancelToken,
            headers: headers,
            onUploadProgress: (progressEvent: any) => {
                options.progress(progressEvent.loaded / progressEvent.total);
            },
        });

        response.data.extension = file.name.split(".").pop();

        return response.data;
    }
}

export default new Vapor();
