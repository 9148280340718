import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { ScribaInstance } from "scriba/Scriba";
import { RootState } from "store/store";
import { IProject } from "types/project";

export interface ProjectState {
    projects?: any[];
    projectsMeta?: any[];
    selectedProject?: IProject | undefined;
    loading: boolean;
    loadingProject: boolean;
    savingProject: boolean;
    attachingMedia: boolean;
    error?: any;
    navigateToProject: boolean;
    downloadingSourceFile: boolean;
}

const initialState: ProjectState = {
    projects: [],
    projectsMeta: [],
    loading: false,
    loadingProject: false,
    savingProject: false,
    attachingMedia: false,
    error: undefined,
    selectedProject: undefined,
    navigateToProject: false,
    downloadingSourceFile: false
};

export const getProjects = createAsyncThunk<any, any>(
    `projects`,
    async (params, thunkApi) => {
        return ScribaInstance.api.getProjects({
            thunkApi, params: params
        });
    }
);

export const getProject = createAsyncThunk<any, any>(
    `project`,
    async (projectId, thunkApi) => {
        return ScribaInstance.api.getProject({ thunkApi, projectId });
    }
);

export const updateProject = createAsyncThunk<any, any>(
    `project/update`,
    async ({ id, ...data }, thunkApi) => {
        return ScribaInstance.api.updateProject({
            thunkApi,
            projectId: id,
            data,
        });
    }
);

export const createProject = createAsyncThunk<any, any>(
    `project/create`,
    async ({ id, ...data }, thunkApi) => {
        return ScribaInstance.api.createProject({ thunkApi, data });
    }
);

export const assochUploadedMediaToProject = createAsyncThunk<any, any>(
    `project/assoch-uploaded-media`,
    async ({ id, ...data }, thunkApi) => {
        return ScribaInstance.api.assochUploadedMediaToProject({
            thunkApi,
            data,
        });
    }
);

export const downloadSourceFile = createAsyncThunk<any, any>(
    `project/downloadSourceFile`,
    async ({ id, mime, save_as }, thunkApi) => {
        return ScribaInstance.api.downloadSourceFile({
            thunkApi,
            id,
            mime,
            save_as,
        });
    }
);

const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setSelectedProject: (state, { payload }) => {
            state.selectedProject = payload;
        },
        removeSelectedProjects: (state) => {
            state.selectedProject = undefined;
            state.projects = [];
        },
        setNavigateToProject: (state, { payload }) => {
            state.navigateToProject = payload;
        },
    },
    extraReducers: (builder) => {
        // Get user/profile projects
        builder.addCase(getProjects.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProjects.fulfilled, (state, { payload }) => {
            state.projects = payload.data;
            state.projectsMeta = payload.meta;
            state.loading = false;
        });
        builder.addCase(getProjects.rejected, (state, { payload }) => {
            if (payload) {
                // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
                state.loading = false;
                state.error = payload;
            }
        });
        // Get project details
        builder.addCase(getProject.pending, (state) => {
            state.loadingProject = true;
        });
        builder.addCase(getProject.fulfilled, (state, { payload }) => {
            state.selectedProject = payload.data;
            state.loadingProject = false;
        });
        builder.addCase(getProject.rejected, (state, { payload }) => {
            if (payload) {
                // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
                state.loadingProject = false;
                state.error = payload;
            }
        });
        // Create new project
        builder.addCase(createProject.pending, (state) => {
            state.savingProject = true;
        });
        builder.addCase(createProject.fulfilled, (state, { payload }) => {
            state.selectedProject = payload.data;
            state.savingProject = false;
            let projects = state.projects || [];
            projects.unshift(payload.data);
            state.projects = projects;
        });
        builder.addCase(createProject.rejected, (state, { payload }) => {
            if (payload) {
                // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
                state.savingProject = false;
                state.error = payload;
            }
        });

        // Update existing project
        builder.addCase(updateProject.pending, (state) => {
            state.savingProject = true;
        });
        builder.addCase(updateProject.fulfilled, (state, { payload }) => {
            state.selectedProject = payload.data;
            state.savingProject = false;
            let projects = state.projects || [];

            const index = projects?.findIndex(
                (element) => element.id === payload.data.id
            );
            if (index >= 0) {
                projects[index] = payload.data;
            } else {
                projects.unshift(payload.data);
            }
            state.projects = projects;
        });
        builder.addCase(updateProject.rejected, (state, { payload }) => {
            if (payload) {
                // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
                state.savingProject = false;
                state.error = payload;
            }
        });

        // Attache media to existing project
        builder.addCase(assochUploadedMediaToProject.pending, (state) => {
            state.attachingMedia = true;
            state.loading = true;
        });
        builder.addCase(
            assochUploadedMediaToProject.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.selectedProject = payload.data;
                state.attachingMedia = false;
                let projects = state.projects || [];

                const index = projects?.findIndex(
                    (element) => element.id === payload.data.id
                );
                if (index >= 0) {
                    projects[index] = payload.data;
                } else {
                    projects.unshift(payload.data);
                }
                state.projects = projects;
            }
        );
        builder.addCase(
            assochUploadedMediaToProject.rejected,
            (state, { payload }) => {
                state.loading = false;

                if (payload) {
                    state.attachingMedia = false;
                    state.error = payload;
                }
            }
        );

        // Download source file
        builder.addCase(downloadSourceFile.pending, (state) => {
            state.downloadingSourceFile = true;
        });
        builder.addCase(downloadSourceFile.fulfilled, (state, { payload }) => {
            state.downloadingSourceFile = false;
        });
        builder.addCase(downloadSourceFile.rejected, (state, { payload }) => {
            state.downloadingSourceFile = false;
            // state.error = payload;
        });
    },
});

const selectState = (state: RootState) => state;

const selectSelf = createDraftSafeSelector(
    selectState,
    ({ projects }) => projects
);
export const selectProjects = createDraftSafeSelector(
    selectSelf,
    ({
        projects,
        projectsMeta,
        loading,
        loadingProject,
        error,
        selectedProject,
        attachingMedia,
        navigateToProject,
        downloadingSourceFile,
    }) => ({
        projects,
        projectsMeta,
        loading,
        loadingProject,
        error,
        selectedProject,
        attachingMedia,
        navigateToProject,
        downloadingSourceFile,
    })
);

export const {
    setSelectedProject,
    removeSelectedProjects,
    setNavigateToProject,
} = projectsSlice.actions;

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default projectsSlice.reducer;
