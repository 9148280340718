import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { ScribaInstance } from "scriba/Scriba";
import { RootState } from "store/store";
import { ITranscriptionInfo } from "types/transcription";

export interface ITranscriptionState {
    transcriptions: ITranscriptionInfo[];
    loading: boolean;
    error?: any;
    selectedTranscription?: any;
    loadingDownload?: number;
}

const initialState: ITranscriptionState = {
    transcriptions: [],
    loading: false,
    error: undefined,
    selectedTranscription: undefined,
    loadingDownload: undefined,
};

export const createNewProjTranscription = createAsyncThunk<any, any>(
    `transcriptions/createNewProjTranscription`,
    async ({ id, ...data }, thunkApi) => {
        return ScribaInstance.api.createNewProjTranscription({
            thunkApi,
            projectId: id,
            data,
        });
    }
);

export const getProjTranscriptionList = createAsyncThunk<any, any>(
    `transcriptions/getProjTranscriptionList`,
    async (id, thunkApi) => {
        return ScribaInstance.api.getProjTranscriptionList({
            thunkApi,
            projectId: id,
        });
    }
);

export const getSingleProjTranscriptionList = createAsyncThunk<any, any>(
    `transcriptions/getSingleProjTranscriptionList`,
    async (id, thunkApi) => {
        return ScribaInstance.api.getSingleProjTranscriptionList({
            thunkApi,
            projectId: id,
        });
    }
);

export const downloadProjectTranscriptionList = createAsyncThunk<any, any>(
    `transcriptions/downloadProjectTranscriptionList`,
    async ({ projectId, transcriptionId, mime, save_as }, thunkApi) => {
        const a = ScribaInstance.api.downloadProjectTranscriptionList({
            thunkApi,
            projectId,
            transcriptionId,
            mime,
            save_as,
        })
        console.log(a)
        return a;
    }
);

const transcriptionsSlice = createSlice({
    name: "transcriptions",
    initialState,
    reducers: {
        setSelectedTranscription: (state, { payload }) => {
            state.selectedTranscription = payload;
        },
        updateExistingTranscription: (state, { payload }) => {
            // Update incoming notification
            state.transcriptions = state.transcriptions.map((el) =>
                el.id === payload.data.id ? payload.data : el
            );
        },
        removeSelectedTranscription: (state) => {
            state.selectedTranscription = undefined;
        },
    },
    extraReducers: (builder) => {
        // POST transcriptions/createNewProjTranscription
        builder.addCase(createNewProjTranscription.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            createNewProjTranscription.fulfilled,
            (state, { payload }) => {
                // Adding new job on table withouth recalling service
                state.transcriptions.unshift(payload.data);
                state.loading = false;
            }
        );
        builder.addCase(
            createNewProjTranscription.rejected,
            (state, { payload }) => {
                if (payload) {
                    state.loading = false;
                    state.error = payload;
                }
            }
        );

        // GET transcriptions/getProjTranscriptionList
        builder.addCase(getProjTranscriptionList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getProjTranscriptionList.fulfilled,
            (state, { payload }) => {
                state.transcriptions = payload.data;
                state.loading = false;
            }
        );
        builder.addCase(
            getProjTranscriptionList.rejected,
            (state, { payload }) => {
                if (payload) {
                    state.loading = false;
                    state.error = payload;
                }
            }
        );

        // GET transcriptions/getSingleProjTranscriptionList
        builder.addCase(getSingleProjTranscriptionList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getSingleProjTranscriptionList.fulfilled,
            (state, { payload }) => {
                state.transcriptions = payload.data;
                state.loading = false;
            }
        );
        builder.addCase(
            getSingleProjTranscriptionList.rejected,
            (state, { payload }) => {
                if (payload) {
                    state.loading = false;
                    state.error = payload;
                }
            }
        );

        // GET transcriptions/downloadProjectTranscriptionList
        builder.addCase(downloadProjectTranscriptionList.pending, (state) => {
            state.loadingDownload = state.selectedTranscription;
        });
        builder.addCase(downloadProjectTranscriptionList.fulfilled, (state) => {
            state.loadingDownload = undefined;
        });
        builder.addCase(
            downloadProjectTranscriptionList.rejected,
            (state, { payload }) => {
                if (payload) {
                    state.loadingDownload = undefined;
                    state.error = payload;
                }
            }
        );
    },
});

const selectState = (state: RootState) => state;

const selectSelf = createDraftSafeSelector(
    selectState,
    ({ transcriptions }) => transcriptions
);
export const selectTranscription = createDraftSafeSelector(
    selectSelf,
    ({ loading, error, transcriptions, loadingDownload }) => ({
        loading,
        error,
        transcriptions,
        loadingDownload,
    })
);

export const {
    setSelectedTranscription,
    removeSelectedTranscription,
    updateExistingTranscription,
} = transcriptionsSlice.actions;

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default transcriptionsSlice.reducer;
