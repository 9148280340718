import { Loading, LoadingProps } from "carbon-components-react";
import classNames from "classnames";
import "./style.scss";
export interface ISpinnerProps extends LoadingProps {
    isFullPage?: boolean;
}

const Spinner: React.FC<ISpinnerProps> = ({ isFullPage, ...props }) => {
    return (
        <div className={classNames("spinner-wrapper", props.className)}>
            <Loading withOverlay={isFullPage} {...props} />
        </div>
    );
};

Spinner.defaultProps = {
    isFullPage: false,
};

export default Spinner;
