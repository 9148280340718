import FileDropzone from "forms/inputs/FileDropzone/FileDropzone";
import useScribaForm from "hooks/useScribaForm";
import { FieldValues } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
    assochUploadedMediaToProject,
    createProject,
    updateProject,
} from "store/slices/projects-slice";
import { AppDispatch } from "store/store";
import { IProject } from "types/project";
import Card from "ui/Card/Card";

export interface IProjectFormProps {
    selectedProject: IProject | undefined;
}

export const ProjectForm = ({ selectedProject }: IProjectFormProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const labelInput = selectedProject?.id
        ? "Edit project's Title"
        : "New Project's Title";

    const submitText = selectedProject?.id && "Edit";

    const onSubmitHandler = async (data: FieldValues) => {
        if (selectedProject?.id) {
            dispatch(updateProject({ ...data, id: selectedProject.id }));
        } else {
            dispatch(createProject(data));
        }
    };

    const onFileUploaded = (event: any) => {
        dispatch(
            assochUploadedMediaToProject({
                ...event,
                project_id: selectedProject?.id,
            })
        );
    };

    const { output } = useScribaForm({
        onSubmitHandler: async (data) => onSubmitHandler(data),
        defaultValues: {
            title: selectedProject?.title,
        },
        settings: {
            textSubmitButton: submitText,
        },
    });

    return (
        <Card withInternalPadding={false}>
            {output([
                {
                    name: "title",
                    elementtype: "input",
                    labelText: labelInput,
                    required: true,
                    id: "new-project-title",
                },
            ])}
            {/* Showing the uploader only if project has no media assigned */}
            {selectedProject?.id && !selectedProject?.media && (
                <FileDropzone
                    maxFiles={1}
                    multiple={false}
                    accept={{
                        "audio/*": [".mp3", ".wav", ".flac", ".amr", ".ogg"], // audio/mpeg audio/x-wav audio/x-flac audio/AMR
                        "video/*": [".mp4", ".ogg", ".webm"], // video/mp4
                    }}
                    infoText={
                        "Valid file formats: MP3, MP4, WAV, FLAC, AMR, OGG, and WebM."
                    }
                    onFileUploaded={onFileUploaded}
                />
            )}
        </Card>
    );
};
