// @ts-nocheck
import { Download } from "@carbon/icons-react";
import {
    DataTable,
    DataTableCustomRenderProps,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    InlineLoading,
} from "carbon-components-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadProjectTranscriptionList, setSelectedTranscription } from "store/slices/transcriptions-slice";
import { AppDispatch } from "store/store";
import { IStatusJob } from "types/transcription";
import { selectProjects } from "store/slices/projects-slice";
import {
    selectTranscription,
    getProjTranscriptionList,
} from "store/slices/transcriptions-slice";
import { makeStatusNotificationClassname } from "../../../utils/style";
import Spinner from "ui/Spinner/Spinner";
import Card from "ui/Card/Card";
import Button from "ui/Buttons/Button";
import { showModal } from "store/slices/modals-slice";
import FormNewJob from "./FormNewJob/FormNewJob";
import { getAvailableLanguages } from "store/slices/languages-slice";
import { convertFileSize } from "utils/convertSize";
import { downloadSourceFile } from "store/slices/projects-slice";
import _map from "lodash/map";
import "./style.scss";
import moment from "moment-timezone";
import _ from "lodash";

const TableJob: React.FC<ITableJob> = ({ ...props }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { selectedProject, downloadingSourceFile } = useSelector(selectProjects);
    const { loadingDownload } = useSelector(selectTranscription);
    const { transcriptions, loading } = useSelector(selectTranscription);

    const spinner = (
        <div className="spinner-container">
            <Spinner />
        </div>
    );

    const headersData = [
        { header: "Title", key: "title" },
        { header: "Lng", key: "lang_code" },
        { header: "Format", key: "format" },
        // { header: "Medical", key: "is_medical" },
        { header: "Started", key: "started_at_formatted" },
        { header: "Duration", key: "duration" },
        { header: "Index", key: "output_start_index" },
        { header: "Cost ($)", key: "formatted_cost" },
        { header: "", key: "status" },
    ];

    const openForm = async () => {
        // Call languages only when making a form
        await dispatch(getAvailableLanguages());
        dispatch(showModal(true));
    };

    const mappedTranscriptions = () => {
        return transcriptions?.map(transcription => {
            const startedAtMoment = moment.utc(transcription.started_at).tz("Europe/Zurich");
            const finishedAtMoment = moment.utc(transcription.finished_at).tz("Europe/Zurich");
            return {
                ...transcription,
                started_at_formatted: (
                    <>
                        {startedAtMoment.format("D/M/YYYY HH:mm:ss")}<small style={{ fontSize: "0.725rem", color: "#999999" }}>{startedAtMoment.format(" (Z)")}</small>
                    </>
                ),
                duration: transcription.finished_at ? finishedAtMoment.diff(startedAtMoment, "minute") + " minutes" : "..."
            }
        })
    }

    const getTranscriptionSaveAs = ({ project, transcription }) => {
        return [
            _.camelCase(project.title),
            _.camelCase(transcription.title),
            transcription.media?.save_as
        ].join("-")
    }

    const formatTranscriptions = (
        dataList: ITranscriptionInfo[] | undefined
    ) => {
        if (!dataList) return [];

        return _map(dataList, (singleItem) => {
            return {
                ...singleItem,
                is_medical: singleItem.is_medical === 0 ? "-" : "yes",
                formatted_cost: singleItem.cost !== null ? singleItem.cost / 100 : "-",
                status: (
                    <>
                        {singleItem.status === IStatusJob.UNKNOWN ? (
                            <div
                                className={makeStatusNotificationClassname(
                                    singleItem.status
                                )}
                            ></div>
                        ) : singleItem.status === IStatusJob.COMPLETED ? (
                            <Link
                                className={"download-transcription"}
                                onClick={() => {
                                    dispatch(setSelectedTranscription(singleItem.id))
                                    dispatch(
                                        downloadProjectTranscriptionList({
                                            projectId: selectedProject.id,
                                            transcriptionId:
                                                singleItem.id,
                                            save_as: getTranscriptionSaveAs({
                                                project: selectedProject,
                                                transcription: singleItem
                                            }),
                                            mime: singleItem.media?.mime,
                                        })
                                    )
                                }
                                }
                            >
                                {loadingDownload === singleItem.id ? (
                                    <Spinner small={true} />
                                ) : (
                                    <Download size={20} />
                                )}
                            </Link>
                        ) : singleItem.status === IStatusJob.PENDING ? (
                            <div
                                className={makeStatusNotificationClassname(
                                    singleItem.status
                                )}
                            ></div>
                        ) : singleItem.status === IStatusJob.FAILED ? (
                            <div
                                className={makeStatusNotificationClassname(
                                    singleItem.status
                                )}
                            ></div>
                        ) : (
                            singleItem.status
                        )}
                    </>
                ),
            };
        });
    };

    useEffect(() => {
        dispatch(getProjTranscriptionList(selectedProject.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    const descriptionTable = (
        <div className="info-file-uploaded">
            <div className="row">
                <span className="download-source">

                    {
                        downloadingSourceFile ? (
                            <InlineLoading description="Downloading source file..." />
                        ) : (
                            <>
                                <Button
                                    kind="tertiary"
                                    iconDescription="Download"
                                    renderIcon={Download}
                                    onClick={() =>
                                        dispatch(
                                            downloadSourceFile({
                                                id: selectedProject.id,
                                                mime: selectedProject.media.mime,
                                                save_as: selectedProject.media.save_as,
                                            })
                                        )
                                    }
                                >
                                    Source file
                                </Button>
                            </>
                        )
                    }
                </span>

                <span>Type: {selectedProject.media.mime}</span>
            </div>
            <div className="row">
                <span>
                    Name:{" "}
                    {selectedProject.media.save_as}
                </span>
                <span>
                    Media:{" "}
                    {selectedProject.media?.size &&
                        convertFileSize(parseInt(selectedProject.media?.size))}
                </span>
            </div>
        </div>
    );

    return (
        <div className="formJob-container">
            {loading ? (
                spinner
            ) : (
                <>
                    <Card withTopMargin={10} withInternalPadding={false}>
                        <div className="info-transcription-table">
                            <DataTable
                                headers={headersData}
                                rows={formatTranscriptions(mappedTranscriptions())}
                                size={"lg"}
                            >
                                {({
                                    rows,
                                    getTableProps,
                                    getRowProps,
                                    getHeaderProps,
                                    getTableContainerProps,
                                    getToolbarProps,
                                    getBatchActionProps,
                                    onInputChange,
                                }: DataTableCustomRenderProps) => {
                                    const batchActionProps =
                                        getBatchActionProps();
                                    return (
                                        <TableContainer
                                            title="Job history"
                                            description={descriptionTable}
                                            {...getTableContainerProps()}
                                        >
                                            <TableToolbar
                                                {...getToolbarProps()}
                                            >
                                                <TableToolbarContent
                                                    aria-hidden={
                                                        batchActionProps.shouldShowBatchActions
                                                    }
                                                >
                                                    {transcriptions.length >
                                                        0 && (
                                                            <TableToolbarSearch
                                                                tabIndex={
                                                                    batchActionProps.shouldShowBatchActions
                                                                        ? -1
                                                                        : 0
                                                                }
                                                                persistent={true}
                                                                onChange={
                                                                    onInputChange
                                                                }
                                                            />
                                                        )}

                                                    <Button
                                                        tabIndex={
                                                            batchActionProps.shouldShowBatchActions
                                                                ? -1
                                                                : 0
                                                        }
                                                        onClick={() =>
                                                            openForm()
                                                        }
                                                        size="small"
                                                        kind="primary"
                                                    >
                                                        Add new job
                                                    </Button>
                                                </TableToolbarContent>
                                            </TableToolbar>
                                            <Table
                                                {...getTableProps()}
                                                size={"lg"}
                                                useZebraStyles={false}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {headersData.map(
                                                            (header: any) => (
                                                                <TableHeader
                                                                    {...getHeaderProps(
                                                                        {
                                                                            header,
                                                                        }
                                                                    )}
                                                                >
                                                                    {
                                                                        header.header
                                                                    }
                                                                </TableHeader>
                                                            )
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <TableRow
                                                            {...getRowProps({
                                                                row,
                                                            })}
                                                        >
                                                            {row.cells.map(
                                                                (cell) => (
                                                                    <TableCell
                                                                        key={
                                                                            cell.id
                                                                        }
                                                                    >
                                                                        <>
                                                                            {
                                                                                cell.value
                                                                            }
                                                                        </>
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    );
                                }}
                            </DataTable>
                        </div>
                    </Card>
                    {/* Submission form */}
                    <FormNewJob selectedProject={selectedProject} />
                </>
            )}
        </div>
    );
};

export default TableJob;
