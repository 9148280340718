import classNames from "classnames";
import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    closeModal,
    selectCurrentModal,
    showModal,
} from "store/slices/modals-slice";
import { AppDispatch } from "store/store";
import { SubmitButton } from "ui/Buttons/SubmitButton";
import Card from "ui/Card/Card";
import ControlledInput from "ui/Input/ControlledInput";
import ModalUi from "ui/Modal/Modal";
import ControlledSelect from "ui/Select/ControlledSelect";
import ControlledCheckbox from "ui/Checkbox/Checkbox";
import { IFieldsForm } from "types/form";
import "./style.scss";

interface ISettings {
    textSubmitButton?: string;
    withReset?: boolean;
    primaryButtonText?: string | undefined;
    className?: string | undefined;
    withWarning?: boolean | undefined;
    withModal?: boolean | undefined;
    withCard?: boolean | undefined;
}

export interface IScribaFormProps {
    onSubmitHandler: (data: FieldValues) => {};
    onRenderFields?: (values: any) => void;
    settings?: ISettings;
    defaultValues?: any;
    updatedValuesOnCondition?: any;
}

export interface IExtraPropsFields {}

/**
 * Hooks that return a controlled form
 * @param param0
 * @returns
 */
const useScribaForm = ({
    onSubmitHandler,
    onRenderFields,
    settings,
    defaultValues,
}: IScribaFormProps) => {
    const methods = useForm();
    const dispatch = useDispatch<AppDispatch>();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setError,
    } = useForm({
        defaultValues,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { modal } = useSelector(selectCurrentModal);
    const watchValues = useWatch({
        control,
    });

    useEffect(() => {
        onRenderFields && onRenderFields(watchValues);
    }, [watchValues, onRenderFields]);

    // Return a node for dedicated object field
    const getInput = (data: IFieldsForm) => {
        const {
            name,
            elementtype,
            revealPasswordAriaLabel,
            required,
            defaultValue,
        } = data;

        let cleanedProps = {
            key: name,
            ...data,
        };

        let registerName = name;
        let registerOptions: any = {
            required: required,
        };

        cleanedProps = {
            ...cleanedProps,
            ...register(registerName, registerOptions),
            control,
            defaultValue,
            errors,
        };

        const inputControlled = (
            <ControlledInput control={control} {...cleanedProps} />
        );
        switch (elementtype) {
            case "select":
                return <ControlledSelect control={control} {...cleanedProps} />;
            case "password":
                const defaultrevealpasswordarialabel =
                    revealPasswordAriaLabel || name;
                cleanedProps = {
                    ...cleanedProps,
                    type: "password",
                    defaultrevealpasswordarialabel:
                        defaultrevealpasswordarialabel,
                };
                return (
                    <ControlledInput
                        control={control}
                        isWithPassword={true}
                        {...cleanedProps}
                    />
                );
            case "checkbox":
                return (
                    <ControlledCheckbox control={control} {...cleanedProps} />
                );
            case "number":
                registerOptions = {
                    ...registerOptions,
                    pattern: /\d+/,
                };
                return inputControlled;
            case "input":
                return inputControlled;
            case "email":
                return inputControlled;
            default:
                return <></>;
        }
    };

    const getInputs = (fields: any[]) => {
        return fields?.map((item) => getInput(item));
    };

    const onSubmit = async (data: FieldValues, wihtReset?: boolean) => {
        try {
            setLoading(true);
            onSubmitHandler(data);
            // Reset fields to prevent new resubmit with same values
            if (wihtReset) {
                reset({ data });
            }
            dispatch(closeModal());
        } catch (error) {
            setLoading(false);
            console.log("Error", error);
        } finally {
            setLoading(false);
        }
    };

    const output = (fields: IFieldsForm[], extraProps: any) => {
        const submitButton = (
            <SubmitButton
                onClick={
                    settings?.withModal
                        ? () => dispatch(showModal(true))
                        : handleSubmit((data) =>
                            onSubmit(data, settings?.withReset)
                        )
                }
                loading={loading}
                defaulttext={settings?.textSubmitButton}
                type={settings?.withModal ? undefined : "submit"}
            />
        );

        // Real form
        const form = (
            <form
                onSubmit={handleSubmit((data) =>
                    onSubmit(data, settings?.withReset)
                )}
                className={classNames("formWrapper", settings?.className)}
            >
                {/* Fields of the form */}
                {getInputs(fields)}

                {!settings?.withModal && (
                    <div className="wrapperSubmitButton">{submitButton}</div>
                )}
            </form>
        );

        // Card Wrapper style
        const card = (
            <FormProvider {...methods}>
                <Card>{form}</Card>
            </FormProvider>
        );

        // Wrapper with modal
        const modalForm = (
            <ModalUi
                callback={handleSubmit((data) => {
                    onSubmit(data, settings?.withReset);
                })}
                primaryButtonText={settings?.textSubmitButton}
                errors={errors}
                open={modal}
                withWarning={settings?.withWarning}
                className={settings?.className}
                control={control}
                setError={setError}
            >
                {form}
            </ModalUi>
        );

        return settings?.withModal
            ? modalForm
            : settings?.withCard
                ? card
                : form;
    };

    return {
        output: (fields: IFieldsForm[], extraProps?: any) =>
            output(fields, extraProps),
    };
};

export default useScribaForm;
