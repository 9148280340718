import { IMedia } from "./project";

export interface ITranscriptionInfo {
    format: string;
    lang_code: string;
    output_start_index: number;
    title: string;
    project_id: string;
    profile_id: string;
    user_id: number;
    id: string;
    updated_at: string;
    created_at: string;
    status: string;
    media: Partial<IMedia>;
    finished_at: any;
    is_medical: any;
    is_phi: any;
    started_at: any;
    transcription_type: any;
}

export enum IStatusJob {
    UNKNOWN = 0, // This could be waiting to do the job or another unknown status
    PENDING = 1,
    COMPLETED = 2,
    FAILED = 3,
}
