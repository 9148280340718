import { Modal } from "carbon-components-react";
import classNames from "classnames";
import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { closeModal } from "store/slices/modals-slice";
import { AssociativeArray } from "types";
import Warning from "../Text/Error/Error";
import "./style.scss";

interface IModal {
    primaryButtonText?: string | undefined;
    secondaryButtonText?: string;
    callback?: any;
    errors?: any;
    children: JSX.Element | JSX.Element[];
    open: boolean;
    withWarning?: boolean;
    className?: string;
    control?: any;
    setError?: any;
    clearErrors?: any;
}

const ModalUi: React.FC<IModal> = ({
    primaryButtonText,
    secondaryButtonText,
    callback,
    children,
    open,
    withWarning,
    className,
    control,
    setError,
    errors,
    clearErrors,
    ...props
}) => {
    const dispatch = useDispatch();
    const [warning, setWarning] = useState<boolean | undefined>(true);
    const values = useWatch({
        control,
    });

    /** Return boolean for empty fields within the form */
    const checkEmptyFields = (values: AssociativeArray): boolean => {
        let checker = true;

        // Skip checker if is medical (this is the default language)
        if (values.is_medical) {
            values.lang_code = "en-US";
        }
        Object.keys(values).forEach((key) => {
            const fieldValue = values[key];
            if (fieldValue === undefined) {
                checker = false;
                setError(key, { type: "manual" });
            }
        });
        return checker;
    };

    const onSubmit = () => {
        // Stop the submission if there are still errors
        if (!checkEmptyFields(values)) return;
        if (withWarning && warning) {
            setWarning(false);
            return;
        } else callback();
    };

    return (
        <Modal
            className={classNames("scriba-modal", className)}
            {...props}
            open={open}
            primaryButtonText={warning ? primaryButtonText : "Confirm"}
            danger={!warning}
            primaryButtonDisabled={!Boolean(values.title)}
            onRequestClose={() => dispatch(closeModal())}
            secondaryButtonText={secondaryButtonText || "Cancel"}
            onRequestSubmit={() => onSubmit()}
        >
            {children}
            {!warning && (
                <div className={"warning"}>
                    <Warning
                        type="error"
                        text="Once confirmed the job will start immediately,
                            do you proceed?"
                    />
                </div>
            )}
        </Modal>
    );
};

ModalUi.defaultProps = {
    primaryButtonText: "Submit",
};

export default ModalUi;
