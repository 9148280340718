import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type IModalsReducer = {
    modal: boolean;
    props?: any;
};

export const initialState: IModalsReducer = {
    modal: false,
    props: {},
};

const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        showModal: (state, { payload }) => {
            state.modal = true;
            state.props = payload;
        },
        closeModal: (state) => {
            state.modal = false;
            state.props = {};
        },
    },
});

const selectState = (state: RootState) => state;

const selectSelf = createDraftSafeSelector(selectState, ({ modals }) => modals);
const selectCurrentModal = createDraftSafeSelector(
    selectSelf,
    ({ modal, props }) => ({
        modal,
        props,
    })
);

export const { showModal, closeModal } = modalsSlice.actions;

export { selectSelf, selectCurrentModal, modalsSlice };

export default modalsSlice.reducer;
