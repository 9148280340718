import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getProjects, selectProjects } from "store/slices/projects-slice";
import { AppDispatch } from "store/store";
import TableProjects from "components/TableProjects/TableProjects";
import { Column } from "carbon-components-react";
import _map from "lodash/map";
import { IProject } from "types/project";
import "./style.scss";

export interface IProjectsListProps {
    onItemSelected?: (itemId: string) => void;
}

/**
 * Page where we display the existing projects
 */
const ProjectsList: React.FC<IProjectsListProps> = ({ onItemSelected }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { projects, projectsMeta, loading } = useSelector(selectProjects);

    const [page, setPage] = useState(1);
    const [step, setStep] = useState(50);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        dispatch(getProjects({ page, step, filter }));
    }, [dispatch, page, step, filter]);

    const headersData = [
        { header: "Project's name", key: "title" },
        { header: "ID", key: "id" },
        // In the empty column we display an icon informing if the project has a file for the job
        { header: "", key: "empty" },
    ];

    const onPaginationChange = (e: any) => {
        setPage(e.page)
        setStep(e.pageSize)
    }

    const onReloadClicked = (e: any) => {
        dispatch(getProjects({ page, step }));
    }

    const updatedProjects = (dataList: IProject[]) => {
        if (!dataList) return [];

        return _map(dataList, (singleItem) => {
            return {
                ...singleItem,
                empty: singleItem.media && singleItem.media,
            };
        });
    };

    const updateQueryFilter = (e: any) => {
        const newFilter = e.target.value
        if (newFilter.length === 0 || newFilter.length > 2 || newFilter.length < filter.length) {
            setPage(1)
            setFilter(newFilter)
        }
    }

    return (
        <Column className="tableProjects-wrapper" sm={4} md={7} lg={7}>
            <TableProjects
                headersData={headersData}
                projects={projects && updatedProjects(projects)}
                projectsMeta={projectsMeta}
                onItemSelected={onItemSelected}
                isLoading={loading}
                onPaginationChange={onPaginationChange}
                onReloadClicked={onReloadClicked}
                onTableSearchChanged={updateQueryFilter}
            />
        </Column>
    );
};

export default ProjectsList;
