import { LoginForm } from "forms/LoginForm/LoginForm";
import React from "react";
import "./style.scss";

export const LoginPage: React.FC = () => {
    return (
        <div className="login-page">
            <LoginForm />
        </div>
    );
};
