import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WithAuth from "hoc/withAuth";
import { AppDispatch } from "store/store";
import { getProject, setSelectedProject } from "store/slices/projects-slice";
import { Grid } from "carbon-components-react";
import Navbar from "ui/Navbar/Navbar";
import { selectUser } from "store/slices/user-slice";
import { subscribeToNotifications } from "socket/subscribe-to-notifications";
import { getNewNotificationStatus } from "store/slices/notifications-slice";
import ProfilePage from "pages/profilePage/ProfilePage";
import "./style.scss";
import { updateExistingTranscription } from "store/slices/transcriptions-slice";

// Component that render the full page of the panel
const WorkspacePage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { token, identity } = useSelector(selectUser);

    const [selectedProjectId, setSelectedProjectId] = useState<
        string | undefined
    >();

    const [notificationChannel, setNotificationChannel] = useState<any>();

    // Load project's details once selected
    useEffect(() => {
        if (selectedProjectId) {
            dispatch(getProject(selectedProjectId));
        } else {
            dispatch(setSelectedProject(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProjectId]);

    // Subscribe to notification
    useEffect(() => {
        if (token) {
            const notificationChannel = subscribeToNotifications(
                {
                    userId: identity?.id,
                    token,
                },
                (data) => {
                    dispatch(getNewNotificationStatus(data));
                    dispatch(updateExistingTranscription(data));
                }
            );

            setNotificationChannel(notificationChannel);
        } else {
            notificationChannel.unsubscribe();
        }

        return () => {
            if (notificationChannel) {
                notificationChannel.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const onItemSelected = (itemId: string) => {
        setSelectedProjectId(itemId);
    };

    return (
        <>
            <Grid className="workspacePage" fullWidth>
                <Navbar className="navbar" onItemSelected={onItemSelected} />
                <ProfilePage onItemSelected={onItemSelected} />
            </Grid>
        </>
    );
};

export default WithAuth(WorkspacePage);
