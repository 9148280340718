export const LOGIN = "login";
export const LOGOUT = "logout";
export const ME = "me";
export const PROJECTS = "projects";
export const SIGNED_STORAGE_URL = "media/signed-storage-url";
export const SAVE_UPLOAD = "media/save-uploaded";
export const LANGUAGES = "languages";
export const TRANSCRIPTIONS = "transcriptions";
export const DOWNLOAD_SUBTITLES = "download-subtitles";
export const NOTIFICATIONS = "notifications";
export const DOWNLOAD_SOURCE = "download-source";
export const SELECT_PROFILE = "select-profile";
