import _map from "lodash/map";
import { IProfile } from "types/user";

/** Method that renders an array using the value as label (see languages)  */
const transformArrayForOptions = (array: any[]) => {
    return _map(array, (format) => {
        return { key: array.indexOf(format), value: format, text: format };
    });
};

/** Method that adapt profiles to options   */
const adaptProfilesToSelectOptions = (array: IProfile[]) => {
    let realValue: any;
    let label: string;
    return _map(array, (format) => {
        realValue = format.id;
        label = format.name;
        return { key: array.indexOf(format), value: realValue, text: label };
    });
};

export { adaptProfilesToSelectOptions, transformArrayForOptions };
