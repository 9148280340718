import React from "react";
import { FieldValues } from "react-hook-form";
import { useDispatch } from "react-redux";
import useScribaForm from "../../hooks/useScribaForm";
import { login } from "../../store/slices/user-slice";
import { AppDispatch } from "../../store/store";

export const LoginForm: React.FunctionComponent = () => {
    const dispatch = useDispatch<AppDispatch>();

    const onSubmitHandler = async (data: FieldValues) => {
        dispatch(login(data));
    };

    const { output } = useScribaForm({
        onSubmitHandler: async (data) => onSubmitHandler(data),
    });

    return output(
        [
            {
                name: "email",
                elementtype: "email",
                labelText: "email",
                required: true,
                id: "email-input",
            },
            {
                name: "password",
                elementtype: "password",
                labelText: "password",
                canrevealpassword: "true",
                id: "password-input",
            },
        ],
        []
    );
};
