import useScribaForm from "hooks/useScribaForm";
import React, { useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ScribaInstance } from "scriba/Scriba";
import { selectLanguages } from "store/slices/languages-slice";
import { createNewProjTranscription } from "store/slices/transcriptions-slice";
import { AppDispatch } from "store/store";
import { IFieldsForm } from "types/form";
import { optionsFormatList, optionsIndexList } from "types/job";
import { IProject } from "types/project";
import Spinner from "ui/Spinner/Spinner";
import { transformArrayForOptions } from "utils/array";

interface IFormJob {
    selectedProject: IProject;
}

const FormNewJob: React.FC<IFormJob> = ({ selectedProject }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { languages } = useSelector(selectLanguages);
    const [condition, setCondition] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const conditionField = "is_medical";
    const medOptions = ["conversation", "dictation"];
    const defaultLanguage = transformArrayForOptions(["en-US"]);
    const optionsLng = languages && transformArrayForOptions(languages);
    const transcriptionMedOptions = transformArrayForOptions(medOptions);

    const { output } = useScribaForm({
        onSubmitHandler: async (data) => onSubmitHandler(data),
        onRenderFields: (values: any) => manageEventTrigger(values),
        defaultValues: {
            // Mandatory value
            is_medical: false,
            is_phi: false,
            transcription_type: "conversation",
        },
        settings: {
            textSubmitButton: "Start",
            withReset: true,
            primaryButtonText: "Submit",
            className: "formJob",
            withModal: true,
            withWarning: true,
        },
    });

    // Manage event trigger condition
    const manageEventTrigger = (values: any) => {
        // values[conditionField] check when the target condition changes
        setCondition(values[conditionField]);
    };

    /** Memo in charge of fields rendering based on dependencies */
    const htmlFormFields = useMemo<IFieldsForm[]>(() => {
        const fields: IFieldsForm[] = [
            // Title
            {
                name: "title",
                elementtype: "input",
                labelText: "New trascription title",
                required: true,
                id: "new-transcription-title",
            },
            // Language
            {
                name: "lang_code",
                elementtype: "select",
                labelText: "Language",
                required: condition ? false : true,
                options: condition ? defaultLanguage : optionsLng,
                withdisabledoption: condition ? false : true,
                id: "lang-code",
                disabled: condition ? true : false,
            },
            // Format
            {
                name: "format",
                elementtype: "select",
                labelText: "Format",
                required: true,
                options: optionsFormatList,
                withdisabledoption: true,
                id: "format-selection",
            },
            // StartIndex
            {
                name: "output_start_index",
                elementtype: "select",
                labelText: "Index",
                required: true,
                options: optionsIndexList,
                withdisabledoption: true,
                id: "index-selection",
            },
            // IsMedical
            // {
            //     name: "is_medical",
            //     elementtype: "checkbox",
            //     labelText: "Create Medical transcript",
            //     required: false,
            //     id: "medical_checkbox", // Mandatory for checkbox
            //     defaultValue: false,
            // },
        ];
        const medicalFields: IFieldsForm[] = [
            {
                name: "is_phi",
                elementtype: "checkbox",
                labelText: "Personal health information",
                required: false,
                id: "is_phi", // Mandatory for checkbox
                defaultValue: false,
            },
            {
                name: "transcription_type",
                elementtype: "select",
                labelText: "Transcription type",
                // Not required when the condition does not exist
                required: false,
                options: transcriptionMedOptions,
                id: "transcription_type",
            },
        ];

        // Return new fields from checkbox selection
        if (condition) return fields.concat(medicalFields);

        return fields;
    }, [condition, optionsLng, transcriptionMedOptions, defaultLanguage]);

    const onSubmitHandler = async (data: FieldValues) => {
        try {
            setLoadingSubmit(true);
            const autostart = ScribaInstance.getGlobalAutostart();
            if (data.is_medical) {
                // Double check on available lng for medical transcription
                data.lang_code = "en-US";
            }
            dispatch(
                createNewProjTranscription({
                    ...data,
                    autostart,
                    id: selectedProject?.id,
                })
            );
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingSubmit(false);
        }
    };

    return (
        <>
            <div className={`form-container`}>
                {loadingSubmit ? Spinner : output(htmlFormFields)}
            </div>
        </>
    );
};

export default FormNewJob;
