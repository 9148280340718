import classNames from "classnames";
import { IStatusJob } from "types/transcription";

/** Return correct classnames for the blob blocks */
const makeStatusNotificationClassname = (value: number): string => {
    const blob = "blob";
    let colorStatus;

    switch (value) {
        case IStatusJob.UNKNOWN:
            colorStatus = "grey";
            break;
        case IStatusJob.PENDING:
            colorStatus = "yellow";
            break;
        case IStatusJob.FAILED:
            colorStatus = "red";
            break;
        default:
            break;
    }
    return classNames(blob, colorStatus);
};

export { makeStatusNotificationClassname };
