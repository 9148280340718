import React from "react";
import { Checkbox } from "carbon-components-react";
import { Controller } from "react-hook-form";
import { IControlledProps } from "types/form";

interface ICheckbox extends IControlledProps {
    labelText: string;
}

const CheckboxControlled: React.FC<ICheckbox> = ({
    id,
    labelText,
    isForm,
    ...props
}) => {
    if (!isForm) {
        return <Checkbox id={id} labelText={labelText} />;
    }

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({ field: { onChange, name: fieldName, value } }) => {
                // Handling value setting
                const handleOnChange = (event: any) => {
                    const isChecked = event.target.checked;
                    onChange(isChecked);
                };

                return (
                    <Checkbox
                        id={id}
                        name={fieldName}
                        labelText={labelText}
                        onChange={handleOnChange}
                        value={value}
                        defaultValue={value}
                        checked={value}
                    />
                );
            }}
        />
    );
};

CheckboxControlled.defaultProps = {
    isForm: true,
};

export default CheckboxControlled;
