import ScribaApi from "./ScribaApi";

class Scriba {
    api: ScribaApi;
    tokenStorageName: string = process.env.REACT_APP_TOKEN_NAME!;

    constructor() {
        this.tokenStorageName = this.getTokenStorageName();
        this.api = new ScribaApi();
        if (this.getToken()) {
            this.setClientAuthHeader(this.getToken());
        }
    }

    getGlobalAutostart(): number {
        if (!process.env.REACT_APP_AUTOSTART) return 0;
        return parseInt(process.env.REACT_APP_AUTOSTART) ?? 0;
    }

    getS3Bucket(): string {
        return process.env.REACT_APP_S3_BUCKET || "";
    }

    getApiBaseUrl(): string {
        return this.api.client.defaults.baseURL || "";
    }

    setClientAuthHeader(token: string): Scriba {
        this.api.client.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${token}`;
        return this;
    }

    getTokenStorageName(): string {
        return `${process.env.REACT_APP_TOKEN_NAME}`;
    }

    getToken(): string {
        return localStorage.getItem(this.tokenStorageName) || "";
    }

    setToken(token: string): Scriba {
        localStorage.setItem(this.tokenStorageName, token);
        this.setClientAuthHeader(token);
        return this;
    }

    clearToken(): Scriba {
        localStorage.removeItem(this.tokenStorageName);
        return this;
    }
}

export default Scriba;

export const ScribaInstance: Scriba = new Scriba();
