import { Logout } from "@carbon/icons-react";
import { HeaderGlobalAction, Modal } from "carbon-components-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "store/slices/user-slice";
import { AppDispatch } from "store/store";
import "./style.scss";

const UserSettings: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const onLogoutButtonClicked = () => {
        dispatch(logout());
    };

    const showWarningAndLogout = () => {
        setShowWarning(!showWarning);
    };

    return (
        <>
            <HeaderGlobalAction
                aria-label="Logout"
                className="user-options"
                onClick={() => showWarningAndLogout()}
            >
                <Logout className="logout-icon" size={24} />
            </HeaderGlobalAction>
            {/* Warning Modal */}
            <Modal
                open={showWarning}
                primaryButtonText="Logout"
                secondaryButtonText={"Cancel"}
                onRequestClose={() => showWarningAndLogout()}
                onRequestSubmit={() => onLogoutButtonClicked()}
                size="sm"
            >
                <h3>Are you sure to logout?</h3>
            </Modal>
        </>
    );
};

export default UserSettings;
