// @ts-nocheck
import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    DataTableCustomRenderProps,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Pagination,
    InlineLoading,
} from "carbon-components-react";
import React, { useState } from "react";
import { AssociativeArray } from "types";
import { IProject } from "types/project";
import { Document, Renew } from "@carbon/icons-react";
import Card from "ui/Card/Card";
import Button from "ui/Buttons/Button";
import "./style.scss";
import classNames from "classnames";

interface ITableProjects {
    headersData: AssociativeArray | any;
    projects: IProject[] | undefined;
    projectsMeta: any | undefined;
    onItemSelected: ((itemId: string) => void) | undefined;
    onPaginationChange: ((e: any) => void) | undefined;
    onReloadClicked: ((e: any) => void) | undefined;
    onTableSearchChanged: ((e: any) => void) | undefined;
    isLoading?: boolean;
}

const TableProjects: React.FC<ITableProjects> = ({
    headersData,
    projects,
    projectsMeta,
    onItemSelected,
    onPaginationChange,
    onReloadClicked,
    onTableSearchChanged,
    isLoading
}) => {

    const [selected, setSelected] = useState<any>();

    const onRowClicked = (id: string) => {
        if (onItemSelected) {
            onItemSelected(id);
        }
        setSelected(id)
    };

    const activatePagination = () => {
        return true
        // return projectsMeta && projectsMeta?.last_page > 1
    }

    const onChange = (e: any) => {
        onPaginationChange(e)
    }

    return (
        <Card withInternalPadding={false}>
            <div className="table-wrapper">
                <DataTable
                    headers={headersData}
                    rows={projects || []}
                    className={"table-projects"}
                >
                    {({
                        rows,
                        getTableProps,
                        getRowProps,
                        getHeaderProps,
                        getToolbarProps,
                        getBatchActionProps,
                    }: DataTableCustomRenderProps) => {
                        const batchActionProps = getBatchActionProps();

                        return (
                            <TableContainer title="Project List">
                                <TableToolbar {...getToolbarProps()}>
                                    <TableToolbarContent
                                        aria-hidden={
                                            batchActionProps.shouldShowBatchActions
                                        }
                                    >
                                        <TableToolbarSearch
                                            tabIndex={
                                                batchActionProps.shouldShowBatchActions
                                                    ? -1
                                                    : 0
                                            }
                                            persistent={true}
                                            onChange={onTableSearchChanged}
                                        />
                                        <Button
                                            tabIndex={
                                                batchActionProps.shouldShowBatchActions
                                                    ? -1
                                                    : 0
                                            }
                                            onClick={onReloadClicked}
                                            iconDescription="Reload list"
                                            hasIconOnly
                                            kind="ghost"
                                            renderIcon={Renew}
                                            disabled={isLoading}
                                        />
                                        <Button
                                            tabIndex={
                                                batchActionProps.shouldShowBatchActions
                                                    ? -1
                                                    : 0
                                            }
                                            onClick={() => onItemSelected("")}
                                            kind="primary"
                                            disabled={isLoading}
                                        >
                                            Add new
                                        </Button>
                                    </TableToolbarContent>
                                </TableToolbar>
                                <Table
                                    useStaticWidth={false}
                                    {...getTableProps()}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {headersData.map((header: any) => (
                                                <TableHeader
                                                    {...getHeaderProps({
                                                        header,
                                                    })}
                                                >
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {
                                        isLoading ? (
                                            <div style={{
                                                width: "100%",
                                                padding: "1rem"
                                            }}>
                                                <InlineLoading description="Loading..." />
                                            </div>
                                        ) : (
                                            <TableBody>
                                                {rows.map((row: any) => (
                                                    <TableRow
                                                        {...getRowProps({ row })}
                                                        onClick={() =>
                                                            onRowClicked(row.id)
                                                        }
                                                        className={classNames({
                                                            "selected": selected === row.id
                                                        })}
                                                    >
                                                        {row.cells.map((cell: any) => {
                                                            const header =
                                                                cell.info.header;
                                                            const value = cell.value;
                                                            return (
                                                                <TableCell
                                                                    key={cell.id}
                                                                >
                                                                    {header ===
                                                                        "title" ? (
                                                                        value
                                                                    ) : header ===
                                                                        "empty" &&
                                                                        value ? (
                                                                        <Document
                                                                            size={20}
                                                                        />
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )
                                    }

                                </Table>
                            </TableContainer>
                        );
                    }}
                </DataTable>
                {
                    activatePagination() ? (
                        <div>
                            <Pagination
                                backwardText="Previous page"
                                forwardText="Next page"
                                itemsPerPageText="Items per page:"
                                page={projectsMeta.current_page}
                                pageNumberText="Page Number"
                                pageSize={projectsMeta.per_page}
                                pageSizes={[
                                    20,
                                    50,
                                    100
                                ]}
                                onChange={onChange}
                                totalItems={projectsMeta.total}
                            />
                        </div>

                    ) : null
                }
            </div>
        </Card>
    );
};

export default TableProjects;
