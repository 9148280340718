import { useSelector } from "react-redux";
import { selectProjects } from "store/slices/projects-slice";
import Spinner from "ui/Spinner/Spinner";
import { ProjectForm } from "forms/ProjectForm/ProjectForm";
import { Column } from "carbon-components-react";
import TableJob from "./tableJob/TableJob";

export interface IProjectDetailsProps {}

const ProjectDetails: React.FC<IProjectDetailsProps> = ({ ...props }) => {
    const { selectedProject, loadingProject, attachingMedia } =
        useSelector(selectProjects);

    return (
        <>
            <Column className="inputTitle-wrapper" sm={4} md={9} lg={9}>
                {loadingProject ? (
                    <Spinner />
                ) : (
                    <>
                        <ProjectForm selectedProject={selectedProject} />
                        {/* Showing the transcription table if project has media */}
                        {selectedProject?.media && <TableJob />}
                    </>
                )}
            </Column>
            {attachingMedia && "Uploading..."}
        </>
    );
};

export default ProjectDetails;
