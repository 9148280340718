import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { IIdentity, IProfile } from "types/user";
import { ScribaInstance } from "../../scriba/Scriba";
import { RootState } from "../store";

export interface UserState {
    identity?: IIdentity;
    loggingIn: boolean;
    loggingOut: boolean;
    token?: string;
    loading: boolean;
    error?: any;
    profiles: IProfile[];
    defaultProfile: IProfile | undefined;
    loadingChoosenProfile: boolean;
    currentProfile: IProfile | undefined;
}
export interface ILoginCredentials {
    email?: any;
    password?: string;
}

const initialState: UserState = {
    identity: undefined,
    loggingIn: false,
    loggingOut: false,
    token: undefined,
    loading: false,
    error: undefined,
    profiles: [],
    defaultProfile: undefined,
    loadingChoosenProfile: false,
    currentProfile: undefined,
};

export const login = createAsyncThunk<any, any>(
    `user/login`,
    async (credentials, thunkApi) => {
        return ScribaInstance.api.login({ thunkApi, data: credentials });
    }
);

export const logout = createAsyncThunk<any>(`user/logout`, async (thunkApi) => {
    return ScribaInstance.api.logout({ thunkApi });
});

export const me = createAsyncThunk<any>(`user/me`, async (value, thunkApi) => {
    return ScribaInstance.api.me({ thunkApi });
});

export const changeGlobalProfile = createAsyncThunk<any, any>(
    `user/changeGlobalProfile`,
    async (id, thunkApi) => {
        return ScribaInstance.api.changeGlobalProfile({
            thunkApi,
            data: id,
        });
    }
);

const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        stickToken: (state, { payload }) => {
            state.token = payload;
        },
        setGlobalProfiles: (state, { payload }) => {
            state.profiles = payload;
        },
    },
    extraReducers: (builder) => {
        // Get logged user
        builder.addCase(me.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(me.fulfilled, (state, { payload }) => {
            state.identity = payload.user;
            state.profiles = payload.user.profiles;
            state.defaultProfile = payload.user.profile;
            state.currentProfile = payload.user.profile;
            state.loading = false;
        });
        builder.addCase(me.rejected, (state, { payload }) => {
            if (payload) {
                state.loading = false;
                state.error = payload;
            }
        });

        // User Login
        builder.addCase(login.pending, (state) => {
            state.loggingIn = true;
        });
        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.token = payload.token;
            state.identity = payload.user;
            state.loggingIn = false;
            ScribaInstance.setToken(payload.token);
        });
        builder.addCase(login.rejected, (state, action) => {
            if (action.payload) {
                state.loggingIn = false;
                state.error = action.payload;
            } else {
                state.error = action.error;
            }
        });

        // User Logout
        builder.addCase(logout.pending, (state) => {
            state.loggingOut = true;
        });
        builder.addCase(logout.fulfilled, (state, { payload }) => {
            state.token = undefined;
            state.identity = undefined;
            state.loggingOut = false;
            ScribaInstance.setToken("");
        });
        builder.addCase(logout.rejected, (state, action) => {
            if (action.payload) {
                state.loggingOut = false;
                state.error = action.payload;
            } else {
                state.error = action.error;
            }
        });

        // Change profile
        builder.addCase(changeGlobalProfile.pending, (state) => {
            state.loadingChoosenProfile = true;
        });
        builder.addCase(changeGlobalProfile.fulfilled, (state, { payload }) => {
            state.currentProfile = payload.data.profile;
            state.loadingChoosenProfile = false;
        });
        builder.addCase(changeGlobalProfile.rejected, (state, { payload }) => {
            if (payload) {
                state.loadingChoosenProfile = false;
                state.error = payload;
            }
        });
    },
});

const selectState = (state: RootState) => state;

const selectSelf = createDraftSafeSelector(selectState, ({ user }) => user);

export const selectUser = createDraftSafeSelector(
    selectSelf,
    ({
        identity,
        token,
        loading,
        error,
        loggingIn,
        loggingOut,
        profiles,
        defaultProfile,
        loadingChoosenProfile,
        currentProfile,
    }) => ({
        identity,
        loggingIn,
        loggingOut,
        token,
        loading,
        error,
        profiles,
        defaultProfile,
        loadingChoosenProfile,
        currentProfile,
    })
);

export const { stickToken, setGlobalProfiles } = userSlice.actions;

export default userSlice.reducer;
