import classNames from "classnames";
import React from "react";
import "./style.scss";

interface ICard {
    children: any;
    className?: string;
    withBorder?: number | null;
    withShadow?: boolean;
    withTitle?: React.ReactNode;
    withTopMargin?: number;
    withInternalPadding?: number | boolean;
}

const Card: React.FC<ICard> = ({
    children,
    className,
    withBorder,
    withShadow,
    withTitle,
    withTopMargin,
    withInternalPadding,
}) => {
    return (
        <div
            className={classNames(
                "card-wrapper",
                className,
                withBorder && `withBorder-${withBorder}`,
                withShadow && "withShadow",
                withTopMargin && `withTopMargin-${withTopMargin}`,
                withInternalPadding &&
                    `withInternalPadding-${withInternalPadding}`
            )}
        >
            {withTitle}
            {children}
        </div>
    );
};

Card.defaultProps = {
    withShadow: true,
    withInternalPadding: 10,
};

export default Card;
