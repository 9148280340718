import Pusher from "pusher-js";

//https://pusher.com/docs/channels/getting_started/javascript/
const init = (token: string) =>
    new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || "", {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        forceTLS: true,
        authEndpoint:
            process.env.REACT_APP_PUSHER_BASE_URL + "broadcasting/auth",
        pongTimeout: 6000,
        activityTimeout: 30000,
        auth: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    });

export default init;
