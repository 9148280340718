import { FC } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { Select, SelectItem, SelectProps } from "carbon-components-react";
import Error from "../Text/Error/Error";

interface IControlledSelect extends SelectProps {
    id: any;
    name: string;
    control?: Control<FieldValues, any> | undefined;
    rules?: any;
    defaultValue?: any;
    withdisabledoption?: boolean;
    options?: any; //SelectItemProps
    isForm?: boolean;
    handleOnChange?: (e: any) => void;
    isDefaultOptionSelected?: boolean;
}

/**
 * In order to use the select we pass options as mandatory options field to reduce duplicate and import of SelectItem
 * @returns
 */
const ControlledSelect: FC<IControlledSelect> = ({
    isForm,
    handleOnChange,
    ...props
}) => {
    if (!isForm) {
        return (
            <Select {...props} onChange={handleOnChange}>
                {props.withdisabledoption ? (
                    <SelectItem
                        disabled
                        value="placeholder-item"
                        text="Choose an option"
                    />
                ) : undefined}
                {props.options.map((item: any, index: string) => (
                    <SelectItem
                        key={"language-select-item-" + index}
                        value={item.value}
                        text={item.text}
                    />
                ))}
            </Select>
        );
    }

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName },
                fieldState: { error },
            }) => {
                // Handling value setting
                const handleOnChange = (event: any) => {
                    onChange(event.target.value);
                };

                return (
                    <Select
                        {...props}
                        onChange={handleOnChange}
                        onBlur={onBlur}
                        name={fieldName}
                        value={props.value}
                        defaultValue={"placeholder-item"}
                        helperText={error && <Error />}
                    >
                        {props.withdisabledoption && (
                            <SelectItem
                                disabled
                                value={"placeholder-item"}
                                text={"Choose an option"}
                                selected={props.isDefaultOptionSelected}
                            />
                        )}
                        {props.options.map((item: any, index: string) => (
                            <SelectItem
                                key={"language-select-item-" + index}
                                value={item.value}
                                text={item.text}
                            />
                        ))}
                    </Select>
                );
            }}
        />
    );
};

ControlledSelect.defaultProps = {
    isForm: true,
    withdisabledoption: false,
    isDefaultOptionSelected: true,
};

export default ControlledSelect;
