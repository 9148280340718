import React from "react";
import { Provider } from "react-redux";
import { store } from "store/store";
import WorkspacePage from "pages/workspacePage/WorkspacePage";

export const App: React.FC = () => {
    return (
        <Provider store={store}>
            <WorkspacePage />
        </Provider>
    );
};
