import { SIGNED_STORAGE_URL } from "config/apiEndpoints";
import WithClient from "hoc/withClient";
import {
    Key,
    ReactChild,
    ReactFragment,
    ReactPortal,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useSelector } from "react-redux";
import { selectProjects } from "store/slices/projects-slice";
import { SubmitButton } from "ui/Buttons/SubmitButton";
import Spinner from "ui/Spinner/Spinner";
import { convertFileSize } from "utils/convertSize";
import vapor from "utils/vapor";
import "./style.scss";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

export type IFileDropzoneProps = DropzoneOptions & {
    clientBaseURL?: string;
    bucket?: string;
    infoText?: string;
    onFileUploaded: (event: any) => void;
};

const FileDropzone = ({
    clientBaseURL,
    bucket,
    multiple,
    maxFiles,
    accept,
    infoText,
    onFileUploaded,
}: IFileDropzoneProps) => {
    const { loading } = useSelector(selectProjects);
    const [progress, setProgress] = useState<any[]>([]);

    const onDrop = useCallback((acceptedFiles) => {
        // acceptedFiles.forEach((file: Blob) => {
        //     const reader = new FileReader()
        //     reader.onabort = () => console.log('file reading was aborted')
        //     reader.onerror = () => console.log('file reading has failed')
        //     reader.onload = () => {
        //         // Do whatever you want with the file contents
        //         const binaryStr = reader.result
        //         console.log(binaryStr)
        //     }
        //     reader.readAsArrayBuffer(file)
        //     setFilesList(
        //         acceptedFiles.map((file: Blob | MediaSource) =>
        //             Object.assign(file, {
        //                 preview: URL.createObjectURL(file),
        //             })
        //         )
        //     );
        // })
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        fileRejections,
    } = useDropzone({ onDrop, multiple, maxFiles, accept });

    const style: any = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const uploadFiles = async () => {
        acceptedFiles.forEach((file, index) => {
            let uploadProgress: SetStateAction<any[any]> = [];
            vapor
                .setSignedStorageUrl(SIGNED_STORAGE_URL)
                .store(file, {
                    baseURL: clientBaseURL,
                    headers: {
                        "Content-type": file.type,
                    },
                    bucket: bucket,
                    // visibility: 'public-read',
                    contentType: file.type,
                    progress: (progress: number) => {
                        uploadProgress[index] = Math.round(progress * 100);
                        setProgress(uploadProgress);
                    },
                })
                .then(async (response) => {
                    // let saveRes = null
                    let postParams: any = {
                        uuid: response.uuid,
                        key: response.key,
                        bucket: response.bucket,
                        name: file.name,
                        mime: file.type,
                        size: file.size,
                        // event_id: props.eventId
                    };

                    onFileUploaded(postParams);
                    // saveRes = await saveMedia(postParams)

                    // if (props.afterUploadSuccess) {
                    //   props.afterUploadSuccess(saveRes, postParams)
                    // }
                })
                .catch((e) => {
                    console.log("uploadFiles", e);
                })
                .finally(() => {
                    // setUploading(false)
                });
        });
    };

    const fileRejectionItems = fileRejections.map(
        ({ file, errors }: { file: any; errors?: any }) => (
            <div key={file.path}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(
                        (e: {
                            code: Key | null | undefined;
                            message:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined;
                        }) => (
                            <li key={e.code}>{e.message}</li>
                        )
                    )}
                </ul>
            </div>
        )
    );

    return (
        <div className="filedropzone__form">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the files here ...</p>
                ) : (
                    <p>
                        Drag 'n' drop some files here, or click to select files
                    </p>
                )}
                {infoText ? <p>{infoText}</p> : null}
            </div>
            <aside className="filedropzone__file-details">
                {acceptedFiles.length > 0 && <h4>File</h4>}
                <div>
                    {acceptedFiles.map((file: any, index) => (
                        <div key={file.path}>
                            {file.path} - {convertFileSize(parseInt(file.size))}{" "}
                            bytes
                            {progress[index] ? ` - ${progress[index]}%` : null}
                        </div>
                    ))}
                </div>
            </aside>
            {fileRejectionItems?.length ? (
                <aside>{fileRejectionItems}</aside>
            ) : null}
            {loading ? (
                <Spinner />
            ) : acceptedFiles.length ? (
                <SubmitButton
                    onClick={() => uploadFiles()}
                    defaulttext={"Upload file"}
                    className={"upload-file-button"}
                />
            ) : (
                <span className="upload-message">Select a file to upload</span>
            )}
        </div>
    );
};

export default WithClient(FileDropzone);
