import classNames from "classnames";
import * as React from "react";
import { Button, IScribaButtonProps } from "./Button";

export interface ISubmitButtonProps extends IScribaButtonProps {
    loading?: boolean;
    defaulttext?: string;
    loadingText?: string;
    className?: string;
    onClick?: any;
}

export const SubmitButton: React.FunctionComponent<ISubmitButtonProps> = (
    props
) => {
    const { defaulttext, loadingText, onClick, loading } = props;

    const cleanProps = {
        ...props,
        loading: props.loading || undefined
    }

    const isLoading = (): boolean => {
        return loading || false;
    };

    const isDisabled = (): boolean => {
        return isLoading();
    };

    const getText = () => {
        return isLoading() ? getLoadingText() : getdefaultText();
    };

    const getdefaultText = (): string => {
        return defaulttext || "Submit";
    };

    const getLoadingText = () => {
        return loadingText || "Wait...";
    };

    return (
        <Button
            className={classNames("submitButton")}
            children={getText()}
            onClick={onClick}
            disabled={isDisabled()}
            {...cleanProps}
        />
    );
};
