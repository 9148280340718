import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { ILanguage } from "types/languages";
import { ScribaInstance } from "../../scriba/Scriba";
import { RootState } from "../store";

export interface ILanguageState {
    loading: boolean;
    error: any;
    languages: ILanguage[];
}

const initialState: ILanguageState = {
    loading: false,
    error: undefined,
    languages: [],
};

export const getAvailableLanguages = createAsyncThunk<any>(
    `language/languages`,
    async (thunkApi) => {
        return ScribaInstance.api.getLanguages({
            thunkApi,
        });
    }
);

const languageSlice = createSlice({
    name: "languages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAvailableLanguages.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getAvailableLanguages.fulfilled,
            (state, { payload }) => {
                state.languages = payload.data;
                state.loading = false;
            }
        );
        builder.addCase(
            getAvailableLanguages.rejected,
            (state, { payload }) => {
                if (payload) {
                    state.loading = false;
                    state.error = payload;
                }
            }
        );
    },
});

const selectState = (state: RootState) => state;

const selectLanguages = createDraftSafeSelector(
    selectState,
    ({ languages }) => languages
);

export { selectLanguages };

export default languageSlice.reducer;
