import {
    Button as CarbonButton,
    ButtonDefaultProps,
} from "carbon-components-react";
import classnames from "classnames";

export interface IScribaButtonProps extends ButtonDefaultProps {
    className?: string;
}

export const Button: React.FC<IScribaButtonProps> = ({
    children,
    className,
    ...props
}) => {
    return (
        <CarbonButton
            className={classnames("scriba-button", {
                className,
            })}
            {...props}
        >
            {children}
        </CarbonButton>
    );
};

// Button.defaultProps = {
//     size: "md",
// };

export default Button;
