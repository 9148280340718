/* eslint-disable react-hooks/rules-of-hooks */
import { me, selectUser } from "../store/slices/user-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { LoginPage } from "pages/loginPage/LoginPage";
import { AppDispatch } from "store/store";
import { ScribaInstance } from "scriba/Scriba";
import Spinner from "ui/Spinner/Spinner";

const WithAuth = (Component: any) => (props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const { identity, loggingIn, loading } = useSelector(selectUser);

    useEffect(() => {
        if (ScribaInstance.getToken()) {
            dispatch(me());
        }
    }, [dispatch]);

    return loggingIn || loading ? (
        <Spinner isFullPage={true} />
    ) : identity?.email ? (
        <Component {...props} />
    ) : (
        <LoginPage />
    );
};

export default WithAuth;
