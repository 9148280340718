import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeGlobalProfile, selectUser } from "store/slices/user-slice";
import { AppDispatch } from "store/store";
import {
    Header,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderName,
    HeaderPanel,
    SelectSkeleton,
    Switcher,
    SwitcherItem,
} from "carbon-components-react";
import { Notification } from "@carbon/icons-react";
import "./style.scss";
import Badge from "ui/Badge/Badge";
import { translateJobStatus } from "utils/translateJobStatus";
import {
    getUnreadNotifications,
    markNotificationAsRead,
    selectSelf,
} from "store/slices/notifications-slice";
import Logout from "./User/Logout";
import classNames from "classnames";
import ControlledSelect from "ui/Select/ControlledSelect";
import { adaptProfilesToSelectOptions } from "utils/array";
import { INotificationObj } from "types/notifications";
import {
    setNavigateToProject,
    setSelectedProject,
} from "store/slices/projects-slice";

interface INavbar {
    className: string;
    onItemSelected: (itemId: string) => void;
}

const Navbar: React.FC<INavbar> = ({ className, onItemSelected, ...props }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { identity, profiles, loadingChoosenProfile, currentProfile } =
        useSelector(selectUser);
    const { notifications, newNotification } = useSelector(selectSelf);

    const [panel, setPanel] = useState<null | string>(null);
    const [isNotificationBadgeVisibile, setIsNotificationBadgeVisibile] =
        useState<boolean>(false);

    const togglePanel = (event: any, panelName: string) => {
        setIsNotificationBadgeVisibile(false);
        event.target.blur();
        setPanel(panelName === panel ? null : panelName);
    };

    const onSwitchProfile = (e: any) => {
        const value = e.target.value;
        dispatch(changeGlobalProfile({ profile_id: value }));
    };

    // Method that switches to other profile if incoming notification belongs to another
    const handleViewNotification = (el: INotificationObj) => {
        try {
            // Check if notification is related to current profile, if yes, proceed normally
            if (el.data.profile_id === currentProfile?.id) {
                onItemSelected(el.data.project_id);
                dispatch(markNotificationAsRead(el.id));
            } else {
                // If not, redirect to other profile and fire same methods
                dispatch(markNotificationAsRead(el.id));
                dispatch(setNavigateToProject(true));
                dispatch(setSelectedProject(el.data.project_id));
                dispatch(
                    changeGlobalProfile({ profile_id: el.data.profile_id })
                );
            }
        } catch (error) {
            console.log(error, "Something went wrong");
        }
    };

    /**
     * Notifications effect
     */
    useEffect(() => {
        if (!newNotification) return;

        setIsNotificationBadgeVisibile(true);
    }, [newNotification]);

    useEffect(() => {
        dispatch(getUnreadNotifications());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Closing the panel if we are switching to another profile */
    useEffect(() => {
        if (!loadingChoosenProfile) return;
        setPanel(null);
    }, [loadingChoosenProfile]);

    return (
        <Header aria-label="header" className={className}>
            <HeaderName className="user-col">
                📃 Scriba - User: {identity?.email}
            </HeaderName>

            {/* Handling multiple profiles - To be reactivated once route ready - If only one profile will not be shown*/}
            {profiles && (
                <div className="profiles-wrapper">
                    {!loadingChoosenProfile ? (
                        <ControlledSelect
                            id={"select-profiles"}
                            name={"profiles"}
                            isForm={false}
                            options={adaptProfilesToSelectOptions(profiles)}
                            defaultValue={currentProfile?.id}
                            noLabel={true}
                            handleOnChange={(e) => onSwitchProfile(e)}
                        />
                    ) : (
                        <SelectSkeleton hideLabel={true} />
                    )}
                </div>
            )}

            <HeaderGlobalBar className="header-main">
                {/* Notification icon */}
                <HeaderGlobalAction
                    aria-label="Notification"
                    onClick={(e) => togglePanel(e, "notifications")}
                    isActive={loadingChoosenProfile}
                    className={classNames(
                        "icon-action-container",
                        panel && "focused"
                    )}
                >
                    <Badge visible={isNotificationBadgeVisibile}>
                        <Notification
                            className={classNames("notifications-icon")}
                            size={24}
                        />
                    </Badge>
                </HeaderGlobalAction>
                {/* Settings icon - To reactivate it once profile setting is ready (or similar feature) */}
                {/* <HeaderGlobalAction
                    aria-label="Settings"
                    isActive={panel === "settings"}
                >
                    <Settings className="settings-icon" size={24} />
                </HeaderGlobalAction> */}
                <Logout />
            </HeaderGlobalBar>
            {/* User logout */}

            <HeaderPanel
                aria-label="Header Panel"
                expanded={panel !== null}
                className={"drawer-container"}
            >
                <Switcher
                    aria-label="Switcher Container"
                    className={"main-switcher"}
                >
                    {notifications &&
                        notifications.map((el, i) => {
                            return (
                                <SwitcherItem
                                    isSelected={Boolean(el?.read_at)}
                                    aria-label={`notification ${i}`}
                                    key={el.id}
                                    className={classNames(
                                        "single-item-drawer",
                                        el.read_at && "read"
                                    )}
                                    onClick={() => {
                                        handleViewNotification(el);
                                    }}
                                >
                                    <div className="notification-view">
                                        <span className="main">
                                            <span className="title">
                                                {el?.data?.project_title}
                                            </span>
                                            <span
                                                className={
                                                    "status" +
                                                    " " +
                                                    translateJobStatus(
                                                        el?.data?.status
                                                    )
                                                }
                                            ></span>
                                        </span>
                                        <span className="info">
                                            <span className="lang">
                                                Lng: {el?.data?.lang_code}
                                            </span>
                                            <span className="format">
                                                Format: {el?.data?.format}
                                            </span>
                                        </span>
                                    </div>
                                </SwitcherItem>
                            );
                        })}
                </Switcher>
            </HeaderPanel>
        </Header>
    );
};

export default Navbar;
