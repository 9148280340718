import {
    PasswordInput,
    TextInput,
    TextInputProps,
} from "carbon-components-react";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "types/form";
import Error from "../Text/Error/Error";

interface ITextInput extends TextInputProps {
    isWithPassword?: boolean;
}

const ControlledInput: FC<HookFormProps & ITextInput> = ({
    isWithPassword,
    ...props
}) => {
    return (
        <Controller
            name={props.name}
            control={props?.control}
            rules={props?.rules}
            defaultValue={props.defaultValue || ""}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
            }) => {
                if (isWithPassword)
                    return (
                        <PasswordInput
                            {...props}
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            name={fieldName}
                            helperText={error && <Error />}
                            placeholder={`Insert ${props.name}`}
                        />
                    );
                else
                    return (
                        <TextInput
                            {...props}
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            name={fieldName}
                            helperText={error && <Error />}
                            placeholder={`Insert ${props.name}`}
                        />
                    );
            }}
        />
    );
};

export default ControlledInput;
