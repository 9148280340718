import pusher from "./base";

export interface ISubscribeToNotification {
    token: string;
    userId: number | undefined;
}

const NOTIFICATION_EVENT =
    "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated";

// const NOTIFICATION_TYPE = "App\\Adam\\Notifications\\TranscriptionNotification";

/**
 * TEST in Pusher dashboard
 * https://dashboard.pusher.com/apps/700209/console
 * CHANNEL = private-users.1
 * EVENT = Illuminate\Notifications\Events\BroadcastNotificationCreated
 * {
  "data": {
    "id": "xxxxx",
    "title": "xxx",
    "status": 2,
    "lang_code": "xxxx",
    "format": "xxxx",
    "profile_id": "xxxx", !!! MANDATORY !!! (check in db the profile_id)
    "project_id": "xxxx",
    "project_title": "xxx",
    "read_at": xxxxx
  },
  "id": "xxxxxx",
  "type": "App\\Adam\\Notifications\\TranscriptionNotification"
}
 */

const subscribeToNotifications = async (
    { token, userId }: ISubscribeToNotification,
    callback: (data: any) => void
) => {
    const channel = pusher(token).subscribe(`private-users.${userId}`);
    channel.bind(NOTIFICATION_EVENT, (data: any) => {
        callback(data);
    });

    // Logger for incoming notifications
    // channel.bind_global((eventName: any, data: any) => {
    //     console.log(
    //         `bind global: The event ${eventName} was triggered with data ${JSON.stringify(
    //             data
    //         )}`
    //     );
    // });

    return channel;
};

export { subscribeToNotifications };
