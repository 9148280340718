import { Column, Grid } from "carbon-components-react";
import ProjectDetails from "components/ProjectDetails/ProjectDetails";
import ProjectsList from "components/ProjectsList/ProjectsList";
import WithProfiles from "hoc/withProfiles";
import React from "react";

interface IProfilePage {
    onItemSelected: (itemId: string) => void;
}

/** Main page where we display the project list and the right form for creating jobs */
const ProfilePage: React.FC<IProfilePage> = ({ onItemSelected }) => {
    return (
        <Column className="colWrapper" sm={4} md={8} lg={16}>
            <Grid className="wrapperProjectList" fullWidth>
                {/* Projects list column */}
                <ProjectsList onItemSelected={onItemSelected} />
                {/* Form column */}
                <ProjectDetails />
            </Grid>
        </Column>
    );
};

export default WithProfiles(ProfilePage);
