import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { ScribaInstance } from "scriba/Scriba";
import { INotificationObj } from "types/notifications";
import { RootState } from "../store";

export type INotifications = {
    loading: boolean;
    error: any;
    notifications: INotificationObj[];
    newNotification: INotificationObj | undefined;
};

export const initialState: INotifications = {
    loading: false,
    error: undefined,
    notifications: [],
    newNotification: undefined,
};

export const getUnreadNotifications = createAsyncThunk<any>(
    `transcriptions/getUnreadNotifications`,
    async (thunkApi) => {
        return ScribaInstance.api.getUnreadNotifications({
            thunkApi,
        });
    }
);

export const markNotificationAsRead = createAsyncThunk<any, any>(
    `transcriptions/markNotificationAsRead`,
    async (id, thunkApi) => {
        return ScribaInstance.api.markNotificationAsRead({
            thunkApi,
            id,
        });
    }
);

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        getNewNotificationStatus: (state, { payload }) => {
            state.newNotification = payload;
            // We want newer notifications on top of existing ones
            state.notifications = [payload, ...state.notifications];
        },
        getAllExistingNotifications: (state, { payload }) => {
            state.notifications = payload;
        },
    },
    extraReducers: (builder) => {
        // GET notifications/getUnreadNotifications
        builder.addCase(getUnreadNotifications.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getUnreadNotifications.fulfilled,
            (state, { payload }) => {
                state.notifications = payload.data;
                state.loading = false;
            }
        );
        builder.addCase(
            getUnreadNotifications.rejected,
            (state, { payload }) => {
                if (payload) {
                    state.loading = false;
                    state.error = payload;
                }
            }
        );

        // POST notifications/getUnreadNotifications
        builder.addCase(markNotificationAsRead.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            markNotificationAsRead.fulfilled,
            (state, { payload }) => {
                state.notifications = state.notifications.map((notification) =>
                    notification.id !== payload.id ? notification : payload
                );
            }
        );
        builder.addCase(
            markNotificationAsRead.rejected,
            (state, { payload }) => {
                if (payload) {
                    state.loading = false;
                    state.error = payload;
                }
            }
        );
    },
});

const selectState = (state: RootState) => state;

const selectSelf = createDraftSafeSelector(
    selectState,
    ({ notifications }) => notifications
);

export const { getNewNotificationStatus } = notificationsSlice.actions;

export { selectSelf, notificationsSlice };

export default notificationsSlice.reducer;
