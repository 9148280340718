// transcription_status 1 == pending
// transcription_status 2 == ok || completed
// transcription_status 3 == failed
const translateJobStatus = (input: number) => {
    let status;
    switch (input) {
        case 1:
            status = "pending";
            break;
        case 2:
            status = "completed";
            break;
        case 3:
            status = "failed";
            break;
        default:
            break;
    }

    return status;
};

export { translateJobStatus };
