import React from "react";
import "./style.scss";

interface IBadge {
    visible: boolean;
    size?: number;
    className?: string;
}

// Badge wrapper for navbar icons,
const Badge: React.FC<IBadge> = ({ visible, size, className, ...props }) => {
    if (!visible) return <>{props.children}</>;
    return (
        <div className={`badge-ui ${className}`}>
            {visible && <span className="badge"></span>} {props.children}
        </div>
    );
};

export default Badge;
